import store from "@/store";
import DcbService from "@/services/dcbService";
import getUserSessionData from "@/functions/getUserSessionData";
import {Brands} from "../brand/brandConfig";
import {ServiceIds} from "../config";
import getServiceId from "@/functions/getServiceId";
import {injectScript} from "@/functions/injectTellium";
import PredictorService from "@/services/predictorService";

export async function userAuthenticate(to, from, next) {
    try {
        await injectScript();
        console.log('Injected Telium and script loaded successfully');
    } catch (error) {
        console.error('Failed to load the script:', error);
    }
    const {brand} = store.state.brandConfig;
    if (brand === Brands.VodacomEvinaSouthAfricaEnglish) await userAuthenticateEncryptedFlow(to, from, next)
    else await userAuthenticateNormalFlow(to, from, next);
}

async function userAuthenticateEncryptedFlow(to, from, next) {
    if (store.state.user || to.name === 'Wifi' || to.name === 'DoiTwo' || to.name === 'TimeoutError') {
        store.commit('setIsLoading', false);
        return next();
    }
    store.commit('setIsLoading', true);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') ?? localStorage.getItem('token');
    const encryptedMsisdn = urlParams.get('encryptedMsisdn') ?? localStorage.getItem('encryptedMsisdn');
    const campaignId = urlParams.get('campaignId') || urlParams.get('cid');
    const googleClickId = urlParams.get('gclid');
    const clickId = urlParams.get('clickId');

    if (campaignId) setCampaignId(campaignId)
    if (googleClickId) setGoogleClickId(googleClickId)
    if (clickId) setClickId(clickId)
    if (encryptedMsisdn && token) {
        return await storeUserDetails(encryptedMsisdn, token, next);
    } else {
        store.commit('setIsLoading', false);
        return next({name: 'Wifi'});
    }
}

async function userAuthenticateNormalFlow(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMsisdn = urlParams.get('e-msisdn');
    if (store.state.user) {
        store.commit('setIsLoading', false);
        return next();
    }
    const userSessionData = getUserSessionData();
    if (userSessionData.msisdn) {
        await setUserData(userSessionData.msisdn, userSessionData.serviceId, next, to);
    }

    const allowedRoutes = ['TermsAndConditions', 'HomeView', 'NewsHome', 'MenuView', 'UpcomingFixtures', 'RequestOtp'];
    if (allowedRoutes.includes(to.name) && !encryptedMsisdn) return next();
    if (!allowedRoutes.includes(to.name) && !store.state.user) return next({name: 'RequestOtp'});

    const campaignId = urlParams.get('campaignId') || urlParams.get('cid');
    const googleClickId = urlParams.get('gclid');
    const msisdn = urlParams.get('msisdn') ?? localStorage.getItem('msisdn');
    const isMiniApp = urlParams.get('miniApp');
    const clickId = urlParams.get('clickId');

    if (msisdn && isMiniApp) return await miniAppLogin(msisdn, next);
    if (campaignId) setCampaignId(campaignId)
    if (googleClickId) setGoogleClickId(googleClickId)
    if (clickId) setClickId(clickId)
    if (encryptedMsisdn) return await encryptedMsisdnAuth(encryptedMsisdn, next, campaignId);

    store.commit('setIsLoading', false);
    return next();
}

async function miniAppLogin(msisdn, next) {
    localStorage.setItem('isMiniApp', true);
    await getMultiTierService(msisdn);
    return await redirectBasedOnUserStatus(next);
}

function setCampaignId(campaignId) {
    store.commit('setCampaignId', campaignId);
}

function setGoogleClickId(googleClickId) {
    store.commit('setGoogleClickId', googleClickId);
}

function setClickId(clickId) {
    store.commit('setClickId', clickId);
}

async function storeUserDetails(encryptedMsisdn, token, next) {
    const userDataResponse = await getUser(encryptedMsisdn, token);
    await setEncryptedUserData(userDataResponse, token, encryptedMsisdn);
    return await redirectBasedOnUserStatusMarketingFlow(userDataResponse.data, next);
}

async function redirectBasedOnUserStatusMarketingFlow(user, next) {
    //TODO look at this logic !!
    store.commit('setIsLoading', false);
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if (user.status === "Active" && user.serviceId !== ServiceIds.free) return next({name: 'LandingPage'});
    else if (user.status === 'Content Blocked') return next({path: `/error/:${user.status}`})
    // else if (action) return await handleActionClick(user, action, next);
    else return next({name: 'DoiTwo'});
    // Need to implement this page else if(user?.contentBlockedEnabled) return next({name: 'ContentBlockOptIn'});
}

async function getUser(encryptedMsisdn, token) {
    const serviceId = await getMultiTierServiceV2(token);
    return await DcbService.getUserViaEncryptedMsisdn(encryptedMsisdn, token, serviceId);
}

async function setEncryptedUserData(userDataResponse, token = undefined, encryptedMsisdn = undefined) {
    await DcbService.trackLoginV2(token);
    store.commit('setUser', userDataResponse.data);
    store.commit('setToken', token);
    store.commit('setEncryptedMsisdn', encryptedMsisdn);
    localStorage.setItem('token', token);
    localStorage.setItem('encryptedMsisdn', encryptedMsisdn);
}

async function encryptedMsisdnAuth(encryptedMsisdn, next, campaignId) {
    const {rawMsisdn} = (await DcbService.decryptMsisdn(encryptedMsisdn)).data;
    await getMultiTierService(rawMsisdn);
    return await redirectBasedOnUserStatus(next, campaignId);
}

async function getMultiTierService(msisdn) {
    let {serviceId} = (await DcbService.getUserViaProductId(msisdn, store.state.productId)).data;
    store.commit('setServiceId', serviceId);
    store.commit('setMsisdn', msisdn);
}

async function getMultiTierServiceV2(token) {
    const getMultiTierServiceResponse = await DcbService.getMultiTierServiceV2(token);
    const getServiceIdByDescriptionResponse = getServiceIdByDescription(getMultiTierServiceResponse.data);
    setServiceIdValues(getServiceIdByDescriptionResponse);
    return getServiceIdByDescriptionResponse
}

function setServiceIdValues(serviceId) {
    localStorage.setItem('serviceId', serviceId);
    store.commit('setServiceId', serviceId);
}

async function redirectBasedOnUserStatus(next, campaignId) {
    const user = (await DcbService.getUserViaProductId(store.state.msisdn, store.state.productId)).data;
    store.commit('setUser', user);
    store.commit('setIsLoading', false);
    if (!user) return next({name: 'WelcomeView'});
    if (campaignId) {
        if (`${campaignId}` === `124` && getServiceId(store.state.user.serviceId) < 2) {
            next({name: 'ConfirmSubscription', params: {price: `5`, interval: 'day'}, replace: true});
        } else return next({name: 'HomeView'});
    } else return next({name: 'HomeView'});
}

function getServiceIdByDescription(array) {
    return array[0].Description;
}

function redirectFreeUserFromPredictorAndFantasy(to, next) {
    if (!store.state.user && (to.path.includes('predictor') || to.path.includes('fantasy'))) return next({name: 'RequestOtp'});
    if (getServiceId(store.state.user.serviceId) < 2 && (to.path.includes('predictor') || to.path.includes('fantasy'))) return next({name: 'MenuView'});
}

async function setUserData(msisdn, serviceId, next, to) {
    store.commit('setMsisdn', msisdn);
    store.commit('setServiceId', serviceId);
    const user = (await DcbService.getUserViaProductId(msisdn, store.state.productId)).data;
    store.commit('setUser', user);
    store.commit('setIsLoading', false);
    redirectFreeUserFromPredictorAndFantasy(to, next)
    await setPredictorUserData(to, msisdn)
    next();
}

async function setPredictorUserData(to, msisdn) {
    const allowedPaths = ['/predictor/upcoming-rounds', '/predictor/my-rounds'];
    if (!allowedPaths.includes(to.path)) return;
    const response = await PredictorService.getUserStats(msisdn);
    store.commit('setPredictorUserStats', response);
}


