import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";
import store from "@/store";

const rugbyZone = 1

export default class PredictorService {
    static async getActiveSports(msisdn, username) {
        const response = await axios.post(`${environmentConfig.predictorService}/player-landing-page`, {
            msisdn: msisdn,
            username: username,
            rugbyZone: rugbyZone,
        })
        return response.data
    }

    static async getUserStats(msisdn) {
        const response = await axios.post(`${environmentConfig.predictorService}/get-user-stats`, {
            msisdn: msisdn,
            rugbyZone: rugbyZone,
        })
        return response.data;
    }

    static async getPlayerResults(msisdn) {
        return await axios.post(`${environmentConfig.predictorService}/get-players-winnings`, {
            msisdn: msisdn,
            rugbyZone: rugbyZone,
        })
    }

    static async getPlayerRoundPredictions(msisdn, roundId) {
        const response = await axios.post(`${environmentConfig.predictorService}/get-player-round-predictions`, {
            msisdn: msisdn,
            roundId: roundId,
            rugbyZone: rugbyZone,
        })
        return response.data
    }

    static async getPlayerRoundHistory(msisdn) {
        const response = await axios.post(`${environmentConfig.predictorService}/get-player-round-history`, {
            msisdn: msisdn,
            sportId: 250,
            rugbyZone: rugbyZone,
        })
        return response.data.recordset
    }

    static async enterPredictions(msisdn, fixture, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/rugby/enter-predictions`, {
            msisdn: msisdn,
            roundId: roundId || store.state.roundId,
            rugbyFixtureId: fixture.rugbyFixtureId,
            winMargin: fixture.margin,
            teamId: fixture.teamId,
            draw: fixture.draw,
            rugbyZone: rugbyZone,
        })
    }

    static async getRoundLeaderboard(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/get-leaderboard`, {
            msisdn: msisdn,
            roundId: roundId,
            rugbyZone: rugbyZone,
        })
    }

    static async getLeagueFixtures(msisdn, roundId) {
        return await axios.post(`${environmentConfig.predictorService}/round-fixtures`, {
            msisdn: msisdn,
            roundId: roundId,
        })
    }
}

