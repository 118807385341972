import {brandConfigObjectMap} from "@/brand/brandConfig";

export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://localhost:8081', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://192.168.88.28:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://staging.rugby-zone.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://rugby-zone.co.za', getBrandConfig('vc-za-en'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        gameTitle: brandConfigObject.gameTitle,
        freePlayServiceId: brandConfigObject.freePlayServiceId,
        newIntegration: brandConfigObject.newIntegration,
        baseRoute: brandConfigObject.baseRoute,
    }
}
