<template>
  <div class="d-flex flex-column align-items-center">
    <div class="app-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 vue-container">
      <LoadingOverlay class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3" v-if="this.isLoading" />
      <router-view />
    </div>
  </div>
</template>

<script>
import LoadingOverlay from "@/views/components/LoadingOverlay";
import {setBrandConfig} from "@/functions/setBrandConfig";
import {mapState, mapMutations} from "vuex";
import FantasyService from "@/services/fantasyService";

export default {
  computed: {
    ...mapState(['isLoading'])
  },
  components: {
    LoadingOverlay
  },
  methods: {
    ...mapMutations(['setDeferredPrompt', 'setFeedArticles']),
    captureEvent() {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault()
        // Stash the event so it can be triggered later.
        this.setDeferredPrompt(e)
      })
    },
    async setNewsFeed() {
      this.setFeedArticles(await FantasyService.getNews());
    },
  },
 beforeMount() {
    this.setNewsFeed();
    this.captureEvent();
    setBrandConfig();
  }
}
</script>

<style lang="scss">
@import "../public/globalStyles/global";

.vue-container {
  position: relative;
  font-family: 'Poppins', sans-serif;
  background-image: url("assets/backgrounds/bg-main.webp");
  background-size: cover;
  height: 100svh;
  overflow-x: hidden;
}
#ot-sdk-btn-floating.ot-floating-button {
  bottom: 50px !important;
  width: 30px !important;
  height: 30px !important;
}

</style>
