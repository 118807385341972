import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class FantasyService {
    static async getNews(type) {
        const failSafeType = type ? type : 'composite'
        const response = await axios.post(`${environmentConfig.fantasyService}/news/get-news-feed-items`, {
            sportId: 5,
            type: failSafeType
        })
        return response.data.recordset
    }

    static async submitCaptain(roundId, msisdn, sportId, playerId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/update-captain`, {
            roundId,
            msisdn,
            sportId,
            playerId
        })
        return response.data
    }

    static async postForwardEntries(entries) {
        const response = await axios.post(`${environmentConfig.fantasyService}/rugby/post-forward-entries`, {
            entries
        })
        return response.data
    }

    static async postBackEntries(entries) {
        const response = await axios.post(`${environmentConfig.fantasyService}/rugby/post-back-entries`, {
            entries
        })
        return response.data
    }

    static async getUserEntries(roundId, msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/cricket/get-user-entries`, {
            msisdn,
            roundId,
        })
        if (response.data.recordset) return response.data.recordset
        else return []
    }

    static async deleteUserEntries(roundId, msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/rugby/delete-user-entries`, {
            msisdn,
            roundId,
        })
        return response.data.recordset
    }

    static async getRoundPlayers(position, roundId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/rugby/get-round-players`, {
            position,
            roundId,
            pageNumber: 1,
            rowsOfPage: 10,
            sportId: 5,
        })
        return response.data
    }

    static async getUpcomingFixtures() {
        const response = await axios.post(`${environmentConfig.fantasyService}/fixtures/get-upcoming-fixtures`, {
            sportId: 5,
        })
        return response.data
    }

    static async getUserLandingStats(msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/profile/get-user-stats`, {
            msisdn: msisdn,
            sportId: 5,
        })
        return response.data
    }

    static async getRounds(msisdn, roundId) {
        const response = await axios.post(`${environmentConfig.fantasyService}/rugby/get-upcoming-rounds`, {
            msisdn: msisdn,
            pageNumber: 1,
            rowsOfPage: 10,
            sportId: 5,
            roundId: roundId
        })
        return response.data
    }

    static async getActiveLeagues() {
        const response = await axios.post(`${environmentConfig.fantasyService}/rugby/get-leagues`, {
            sportId: 5,
        })
        return response.data
    }

    static async getTopPicks(leagueAbbriviation, season) {
        const response = await axios.post(`${environmentConfig.fantasyService}/rugby/get-top-players-in-league`, {
            ref: leagueAbbriviation,
            seasonRef: season,
            sportId: 5,
        })
        return response.data
    }

    static async getRoundLeaders(msisdn, id) {
        const response = await axios.post(`${environmentConfig.fantasyService}/rugby/get-round-leaders`, {
            id,
            msisdn,
            sportId: 5,
            userAccessId: 11
        })
        return response.data
    }

    static async getCompletedRounds(msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyService}/rugby/get-completed-rounds`, {
            msisdn: msisdn,
            pageNumber: 1,
            rowsOfPage: 10,
            sportId: 5,
        })
        return response.data
    }
}

